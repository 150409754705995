<template>
  <el-main class="rh-medform">
    <form-fpostgradedit :ismeddetail="true" :routetolist="contRouteToList" />
  </el-main>
</template>
<script>
import FormFpostgradedit from "@/components/rh/formulaires/FormFpostgradedit";

export default {
  components: { "form-fpostgradedit": FormFpostgradedit },
  computed: {
    contRouteToList() {
      return {
        name: "med_fpostgrads",
        params: {
          idmed: this.$route.params.idmed,
        },
      };
    },
  },
};
</script>
